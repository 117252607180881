import React, {useState} from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Model from '../components/Model'
import Controls from "../components/Controls.js"
import { Canvas,Suspense } from "react-three-fiber"
import { useOctaModelData } from "../hooks/useOctaModelData"
import {Container,Grid, Card, TextField} from '@material-ui/core'
import { useSpring, animated, config } from 'react-spring'
import WaveBackground from '../components/WaveBackground'
import Typography from '@material-ui/core/Typography'
import AWS from 'aws-sdk'
import Paper from '@material-ui/core/Paper';

const modelBucketName = process.env.GATSBY_modelBucketName;
const bucketRegion = process.env.GATSBY_bucketRegion;
const IdentityPoolId = process.env.GATSBY_IdentityPoolId;

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    background: 'linear-gradient(180deg, #082C44 0%,  #9400FF 50%, #FFA94C 100%)',
    //background: 'radial-gradient(ellipse at top, #e66465, transparent), radial-gradient(ellipse at bottom, #4d9f0c, transparent)',
    height: '100vh',
  },
  blog:{

  },
  paper:{
      margin: theme.spacing(2),
      height: '900px',
      backgroundColor: 'white',
      alignItems: 'center',
      display: 'flex'
  },
  background:{    
      display: 'block',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      backgroundSize: 'cover',
      zIndex: -101,
      position: 'fixed',
  },
  text: {
    margin: 'auto',
    color: 'white',
    transform: 'translateZ(20px)',
    willChange: 'transform',
    fontWeight: '500'
  },
  logo: {
    position:'absolute'
  },
}))

const AnimatedPaper = animated(Paper);

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: modelBucketName }
});

async function getAllKeys(params,  allKeys = []){
  const response = await s3.listObjectsV2(params).promise();
  response.Contents.forEach(obj => allKeys.push(obj.Key));

  if (response.NextContinuationToken) {
    params.ContinuationToken = response.NextContinuationToken;
    await getAllKeys(params, allKeys); // RECURSIVE CALL
  }
  return allKeys;
}

s3.createMultipartUpload()

const FormPage = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [model, setModel] = useState([]);
  const [filename, setFilename] = useState({}); 
  const [hasError, setErrors] = useState(false);
  const [state, toggle] = useState(true);

  let config = {
    width: 500,
    height: 500,
    scale: 1 ,
    rotate: 0,
    translateX: 0,
    translateY: 0,
  }
  
  const handleSubmit = (evt) => {
      evt.preventDefault();
      console.log(model[0])
      console.log(model[0].name)
      console.log(name)

      const params = {
          Bucket: modelBucketName + '/' + name,
          Key: model[0].name,
          Body: model[0],
          ACL: "public-read",
    }

        // Uploading files to the bucket
      s3.upload(params, function(err, data) {
        if (err) {
            throw err;
        }
        console.log(`File uploaded successfully. ${data.Location}`);
      })
  }
  // Downloading list of files in bucket
  const handleList = async (evt) => {
      evt.preventDefault();
      console.log(modelBucketName)
      const params = {
            Bucket: modelBucketName,
            MaxKeys: 5
      };
      const allKeys = await getAllKeys(params)
      .then(allKeys => setFilename(allKeys))
      .catch(err => setErrors(err));
      
  }
  // calculate
  // const trans = (transX, transY ,xRotation, s) => `translate(${transX},${transY}) rotate(${xRotation} 250 250) scale(${s})`
  const circleClip = (p) => `circle(${p}% at 50% 50%)`
  const gradient = (startColor, endColor) => `linear-gradient(0deg, ${startColor} 0%,  #9400FF 50%, ${endColor} 100%)`
  // const gradient1 = (startColor, endColor) => `radial-gradient(at 50% 50%, ${endColor}, #9400FF , ${startColor})`
  // const cards = (startColor, endColor) => `linear-gradient(180deg, ${endColor} 0%,  #9400FF 50%, ${startColor} 100%)`

  const svg = useSpring({ config: config.wobbly, from: { transform: [0,0,0,1] }, transform: state ? [config.translateX,config.translateX,180,config.scale] : [0,0,0,1]})
  const circelMask = useSpring({config: config.wobbly, delay: 1000,  percent:state ? 100 : 20, from: {percent: 20}})
  const opacityWave = useSpring({ delay: 1000, opacity: 1, from: {opacity: 0}})
  const opacity = useSpring({config: config.wobbly, from: {opacity: 1}, opacity: state ? 0 : 0,})

  const background = useSpring({config: config.wobbly, color: state ? ['#082C44', '#FFA94C'] : ['#082C44', '#FFA94C'], from: ['#082C44', '#FFA94C']})


  return(
    <Layout>

    <animated.div className={classes.root} style={{clipPath: circelMask.percent.interpolate(circleClip), }}>

        {/* <animated.div className={classes.background} style={opacityWave}>
            <WaveBackground />
        </animated.div> */}
        {/* <animated.svg className={classes.logo} width={config.width} height={config.height} fill="none" style={opacity}
            xmlns="http://www.w3.org/2000/svg">
            <animated.g>
                <path
                    d="M349.911 209.435L249.81 109.036L209.411 149.435L309.661 249.685L209.911 349.435L250.159 389.684L371.071 268.773L450.16 189.684L409.911 149.435L349.911 209.435Z"
                    fill="white" />
                <path
                    d="M49.9109 308.935L209.411 149.435L249.81 109.036L290.209 149.435L190.309 249.335L290.409 349.435L250.159 389.684L150.06 289.584L90.3099 349.334L49.9109 308.935Z"
                    fill="white" />
            </animated.g>
        </animated.svg> */}
            <AnimatedPaper className={classes.paper} style={opacityWave}>

            <Grid container   justify="center"
    spacing={8}>
            <Grid item lg={12}>
                <Typography variant="h2"> Submit Model</Typography>
            </Grid> 

                <Grid item lg={12}>
                    <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xl={12}>
                        <TextField
                            required
                            id="Name"
                            name="Name"
                            label="Username"
                            value = {name}
                            onChange={e=> setName(e.target.value)}/>
                            
                        </Grid>

                        <Grid item xl={12}>
                            <input type="file" value={model.name} onChange={e=> setModel([...model, e.target.files[0]])}/>
                        </Grid>

                        <Grid item xl={12}>
                        <input type="submit" value="Submit" />
                        </Grid>
                    </Grid>
                    </form>
                </Grid>
                <Grid item lg={12}>
                    <form onSubmit={handleList}>
                    <Grid container>
                        <Grid item xl={12}>
                        <input type="submit" value="List"/>
                          {/* {!this.state.filename ? (
                            <Grid></Grid>
                          ) : (
                            <Grid>{this.state.filename}</Grid>  
                          )} */}
                          <Grid>{JSON.stringify(filename)}</Grid>
                          <Grid>Has Error: {JSON.stringify(hasError)}</Grid>
                        </Grid>
                    </Grid>
                    </form>
                </Grid>
                    
            </Grid> 
            </AnimatedPaper>
        {/* transform={svg.transform.interpolate(trans)} */}



    </animated.div>
    <animated.div className={classes.blog}
        style={{clipPath: circelMask.percent.interpolate(circleClip), background: background.color.interpolate(gradient) }}>
        <Card />
        <Card />
        <Card />
    </animated.div>


    </Layout>

  // <Layout>
  //   <SEO title="Page two" />  
  //   <form onSubmit={handleSubmit}>
  //   <div style={{position: 'absolute'}}>
  //   <animated.div className={classes.background} /*style={opacityWave}*/>
  //     {WaveBackground}
   
  //     <label>
  //       Model
  //       <input
  //         type="file"
  //         value={model.name}
  //         onChange={e => setModel([...model, e.target.files[0]])
  //         }
  //       />
  //     </label>
  //     <TextField id="standard-basic0" label="Name" />
  //     <input type="submit" value="Submit" />
  //     </animated.div>
  //     </div>
  //   </form>
    
  // </Layout>


  )
}

export default FormPage
